import axios from 'axios';

export async function getSites(serverUrl: string, token: string) {
  try {
    const response = await axios.get(`${serverUrl}/sites_and_locations`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching sites:', error);
  }
}
