import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Site } from '../types';
import L from 'leaflet';
import { Button, Stack } from '@mui/material';

interface MapProps {
  sites: Site[];
  center: [number, number];
  highlightedSite: number | null;
}

export function calculateMapCenter(siteData: Site[]): [number, number] {
  // Calculate the coordinates of the center of the map
  const boundingBox = siteData.reduce(
    (box: { minLat: number; maxLat: number; minLong: number; maxLong: number }, site: Site) => {
      if (site.lat < box.minLat) {
        box.minLat = site.lat;
      }
      if (site.lat > box.maxLat) {
        box.maxLat = site.lat;
      }
      if (site.long < box.minLong) {
        box.minLong = site.long;
      }
      if (site.long > box.maxLong) {
        box.maxLong = site.long;
      }
      return box;
    },
    {
      minLat: Infinity,
      maxLat: -Infinity,
      minLong: Infinity,
      maxLong: -Infinity
    }
  );

  const centerLat = (boundingBox.minLat + boundingBox.maxLat) / 2;
  const centerLongitude = (boundingBox.minLong + boundingBox.maxLong) / 2;
  return [centerLat, centerLongitude];
}

const bigIconClass = L.Icon.extend({
  options: {
    // default icon image and shadow
    iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
    iconSize: [38, 62],
    iconAnchor: [19, 62],
    shadowSize: [62, 62],
    shadowAnchor: [19, 62]
  }
});

const Map: React.FC<MapProps> = ({ sites, center, highlightedSite }) => {
  const navigate = useNavigate();

  return (
    <MapContainer
      center={center}
      zoom={6}
      style={{ height: '100%', width: '100%' }}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {sites.map((site) => (
        <Marker
          key={site.site_id}
          position={[site.lat, site.long]}
          icon={site.site_id === highlightedSite ? new bigIconClass() : new L.Icon.Default()}
        >
          <Popup>
            <Stack alignItems="flex-start">
              <strong>{site.address}</strong>
              Postcode: {site.postcode}
              <Button
                variant="text"
                size="small"
                onClick={() => navigate(`/site/${site.site_id}/details`)}
              >
                See details
              </Button>
            </Stack>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
