import React from 'react';
import { Device, Site } from '../../types';
import { ChartData } from '../DataValues';
import Grid from '@mui/material/Unstable_Grid2';
import DevicesTable from '../../components/tuya/DevicesTable';
import SiteInfo from '../../components/tuya/SiteInfo';
import MeterInfo from '../../components/tuya/MeterInfo';
import { CircularProgress, Stack } from '@mui/material';

interface SiteContentProps {
  chartData: ChartData | undefined;
  devices: Device[];
  siteInfo: Site | null;
  weeklyChartData: ChartData | undefined;
}

const SiteContent: React.FC<SiteContentProps> = ({
  chartData,
  devices,
  siteInfo,
  weeklyChartData
}) => {
  if (siteInfo === null) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '40px' }}
      >
        <CircularProgress />
      </Stack>
    );
  } else {
    return (
      <Grid container spacing={4}>
        <Grid sm={5}>{siteInfo ? <SiteInfo site={siteInfo} /> : null}</Grid>
        <Grid sm={5}>{siteInfo ? <MeterInfo site={siteInfo} /> : null}</Grid>

        <Grid sm={12}>
          <DevicesTable devices={devices} siteInfo={siteInfo} />
        </Grid>
      </Grid>
    );
  }
};

export default SiteContent;
