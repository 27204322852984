import { MeasurementValue, Prediction } from './pages/DataValues';

export function dateToTimestamp(datetime: string) {
  const date = new Date(datetime);
  const offset_h = date.getTimezoneOffset();
  const offset_ms = offset_h * 60 * 1000;
  return date.valueOf() - offset_ms;
}

export function getCurrentTimestamp() {
  const currentDate = new Date();
  const offset_h = currentDate.getTimezoneOffset();
  const offset_ms = offset_h * 60 * 1000;
  return currentDate.valueOf() - offset_ms;
}

export function getDates() {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const ereyesterdayDate = new Date(currentDate);
  ereyesterdayDate.setDate(currentDate.getDate() - 2);
  const fiveDaysAgoDate = new Date(currentDate);
  fiveDaysAgoDate.setDate(currentDate.getDate() - 5);
  const thirdDayDate = new Date(currentDate);
  thirdDayDate.setDate(currentDate.getDate() + 3);
  let oneYearAgoDate = new Date(currentDate);
  oneYearAgoDate.setDate(currentDate.getDate() - 365);
  return {
    today: currentDate,
    ereyesterday: ereyesterdayDate,
    fiveDaysAgoDate: fiveDaysAgoDate,
    thirdDay: thirdDayDate,
    oneYearAgo: oneYearAgoDate
  };
}

export function formatDate(dateString: string) {
  try {
    const date = new Date(dateString);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formattedDate = date.toLocaleDateString('en-GB', {
      timeZone: browserTimezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    return formattedDate;
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateString;
  }
}

export function formatTime(dateString: string) {
  try {
    const date = new Date(dateString);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formattedTime = date.toLocaleTimeString('en-GB', {
      timeZone: browserTimezone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return formattedTime;
  } catch (error) {
    console.error('Error formatting time:', error);
    return dateString;
  }
}

export function calculateDuration(isoStart: string, isoEnd: string): string {
  const start = new Date(isoStart).getTime();
  const end = new Date(isoEnd).getTime();
  const minutes = (end - start) / 1000 / 60; // convert to minutes
  return `${minutes} min`;
}

export function scaleValues(values: MeasurementValue[], factor: number): MeasurementValue[] {
  return values.map((obj) => ({ value: obj.value * factor, timepoint: obj.timepoint }));
}

export function scalePredictions(values: Prediction[], factor: number): Prediction[] {
  return values.map((obj) => ({ value: obj.value * factor, predicted_for: obj.predicted_for }));
}

export function getMeterStatus(site: { latest_timepoint: string }): string {
  const latestTimepoint = new Date(site.latest_timepoint);
  const now = new Date();
  const hoursDifference = (now.getTime() - latestTimepoint.getTime()) / (1000 * 60 * 60);

  if (hoursDifference < 72) {
    return 'connected';
  } else {
    return 'noData';
  }
}
