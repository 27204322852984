import { Chip } from '@mui/material';
import { CheckCircle, Error, HelpOutline } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';

const StatusChip = ({ status }) => {
  const chipConfig = {
    connected: {
      icon: <CheckCircle />,
      label: 'Connected',
      bgcolor: '#24dac830',
      iconColor: '#23dac8',
      textColor: '#18978a'
    },
    inProgress: {
      icon: <CachedIcon />,
      label: 'In Progress',
      bgcolor: '#ebebebba',
      iconColor: '#bab5b5',
      textColor: '#5b5959'
    },
    notFound: {
      icon: <Error />,
      label: 'Not Found',
      bgcolor: '#ffd5d5ba',
      iconColor: '#ff4d4d',
      textColor: '#8b0000'
    },
    invalidID: {
      icon: <Error />,
      label: 'Invalid ID',
      bgcolor: '#ffd5d5ba',
      iconColor: '#ff4d4d',
      textColor: '#8b0000'
    },
    noData: {
      icon: <Error />,
      label: 'No Data',
      bgcolor: '#ffd5d5ba',
      iconColor: '#ff4d4d',
      textColor: '#8b0000'
    },
    problemDetected: {
      icon: <HelpOutline />,
      label: 'Problem detected',
      bgcolor: '#fff3d6',
      iconColor: '#ffa500',
      textColor: '#805300'
    }
  };

  const { icon, label, bgcolor, iconColor, textColor } = chipConfig[status] || chipConfig.noData;

  return (
    <Chip
      icon={icon}
      label={label}
      sx={{
        bgcolor: bgcolor,
        color: textColor,
        '& .MuiChip-icon': {
          color: iconColor,
          fontSize: '22px',
          marginRight: '-10px',
          marginLeft: '10px'
        }
      }}
    />
  );
};

export default StatusChip;
