import React from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar
} from '@mui/material';
import {
  Home as HomeIcon,
  Map as MapIcon,
  Notifications as NotificationsIcon,
  Savings as SavingsIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

export const drawerWidth = '220px';

const Sidebar = () => {
  const iconMinWidth = 37;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.dark',
          color: 'info.main'
        }
      }}
    >
      <Toolbar>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          style={{ marginTop: '15px' }}
        >
          {/* Sidebar Content */}
          <img src="/logo_white_nobckng.png" alt="Logo" style={{ height: '42px' }} />
          <List sx={{ width: '100%' }}>
            {/* Dashboard Button */}
            <ListItemButton component={Link} to="/dashboard" disableGutters>
              <ListItemIcon sx={{ minWidth: `${iconMinWidth}px` }}>
                <HomeIcon sx={{ color: 'info.main' }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" primaryTypographyProps={{ variant: 'h6' }} />
            </ListItemButton>

            {/* Sites Button */}
            <ListItemButton component={Link} to="/sites" disableGutters>
              <ListItemIcon sx={{ minWidth: `${iconMinWidth}px` }}>
                <MapIcon sx={{ color: 'info.main' }} />
              </ListItemIcon>
              <ListItemText primary="Sites" primaryTypographyProps={{ variant: 'h6' }} />
            </ListItemButton>

            {/* Market Button */}
            <ListItemButton disableGutters>
              <ListItemIcon sx={{ minWidth: `${iconMinWidth}px` }}>
                <SavingsIcon sx={{ color: 'info.main' }} />
              </ListItemIcon>
              <ListItemText primary="Market" primaryTypographyProps={{ variant: 'h6' }} />
            </ListItemButton>

            {/* Events Button */}
            <ListItemButton component={Link} to="/events" disableGutters>
              <ListItemIcon sx={{ minWidth: `${iconMinWidth}px` }}>
                <NotificationsIcon sx={{ color: 'info.main' }} />
              </ListItemIcon>
              <ListItemText primary="Events" primaryTypographyProps={{ variant: 'h6' }} />
            </ListItemButton>
          </List>
        </Stack>
      </Toolbar>
    </Drawer>
  );
};

export default Sidebar;
