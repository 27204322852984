import { Site } from '../../types';
import React, { useEffect, useState } from 'react';
import { Prediction, AggregateValue, BaselineValue, EKZChartData } from '../DataValues';
import Chart from '../../components/ekz/Chart';
import Grid from '@mui/material/Unstable_Grid2';
import { getDates } from '../../util';
import axios from 'axios';
interface DashboardProps {
  serverUrl: string;
  sites: Site[];
}

const DashboardTuya: React.FC<DashboardProps> = ({ sites, serverUrl }) => {
  const [chartDataAllSources, setChartDataAllSources] = useState<EKZChartData>({
    consumptionValue: null,
    consumptionEVsValue: null,
    consumptionSiteValue: null,
    consumptionEVsHistoricalValue: null,
    productionValue: null,
    flexibilityValue: null,
    consumptionForecast: null,
    productionForecast: null,
    flexibilityForecast: null,
    events: null,
    invitations: null,
    baselineValue: null
  });
  const [chartDataEVs, setChartDataEVs] = useState<EKZChartData>({
    consumptionValue: null,
    consumptionEVsValue: null,
    consumptionSiteValue: null,
    consumptionEVsHistoricalValue: null,
    productionValue: null,
    flexibilityValue: null,
    consumptionForecast: null,
    productionForecast: null,
    flexibilityForecast: null,
    events: null,
    invitations: null,
    baselineValue: null
  });
  const [chartDataDeterministicBaseline, setChartDataDeterministicBaseline] =
    useState<EKZChartData>({
      consumptionValue: null,
      consumptionEVsValue: null,
      consumptionSiteValue: null,
      consumptionEVsHistoricalValue: null,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    });

  const [activeAggregatePowerForAllSources, setAggregateActivePowerForAllSources] = useState<
    AggregateValue[]
  >([]);
  const [activeAggregatePowerForEVsPrediction, setActiveAggregatePowerForEVsPrediction] = useState<
    Prediction[]
  >([]);
  const [activeAggregatePowerForEVs, setAggregateActivePowerForEVs] = useState<AggregateValue[]>(
    []
  );
  const [deterministicBaseline, setDeterministicBaseline] = useState<BaselineValue[]>([]);

  useEffect(() => {
    const token = localStorage.getItem('user_token');

    const fetchAggregateActivePowerForAllSources = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const aggregateActivePowerForAllSourcesResponse = await axios.get(
          `${serverUrl}/aggregation_values/2`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        console.log(
          'aggregateActivePowerForAllSourcesResponse',
          aggregateActivePowerForAllSourcesResponse
        );
        setAggregateActivePowerForAllSources(aggregateActivePowerForAllSourcesResponse.data);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchAggregateActivePowerForEVs = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const aggregateActivePowerForEVsResponse = await axios.get(
          `${serverUrl}/aggregation_values/3`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        console.log('aggregateActivePowerForEVsResponse', aggregateActivePowerForEVsResponse);
        setAggregateActivePowerForEVs(aggregateActivePowerForEVsResponse.data);

        const EVsResponse = JSON.parse(JSON.stringify(aggregateActivePowerForEVsResponse.data));
        EVsResponse.forEach((item: any) => {
          const timepointAsDate = new Date(item.timepoint);
          timepointAsDate.setDate(timepointAsDate.getDate() + 5);
          item.predicted_for = timepointAsDate.toISOString();
        });
        console.log(EVsResponse);
        const pastPredictionData = JSON.parse(
          JSON.stringify(aggregateActivePowerForEVsResponse.data)
        ).map((item: AggregateValue) => ({
          predicted_for: item.timepoint,
          value: item.value * 2
        }));
        const combinedData = [...pastPredictionData, ...EVsResponse];
        console.log('combiendData', combinedData);
        setActiveAggregatePowerForEVsPrediction(combinedData);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const createDeterministicBaselineTimeSeries = async () => {
      const deterministicBaselineTimeSeries = [];
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      function checkTime(hour: number) {
        if (hour >= 19 || hour < 0) {
          return 0;
        } else {
          return 1;
        }
      }

      for (let date = fiveDaysAgoDate; date <= dateNow; date.setHours(date.getHours() + 1)) {
        const hour = date.getHours();
        const value = checkTime(hour);

        deterministicBaselineTimeSeries.push({
          timepoint: date.toString(),
          value: value
        });

        setDeterministicBaseline(deterministicBaselineTimeSeries);
      }
    };
    if (token !== '') {
      fetchAggregateActivePowerForAllSources();
      fetchAggregateActivePowerForEVs();
      createDeterministicBaselineTimeSeries();
    }
  }, [serverUrl, sites]);

  useEffect(() => {
    const newChartDataAllSources: EKZChartData = {
      consumptionValue: null,
      consumptionEVsValue: activeAggregatePowerForEVs,
      consumptionSiteValue: activeAggregatePowerForAllSources,
      consumptionEVsHistoricalValue: null,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    };
    setChartDataAllSources(newChartDataAllSources);
  }, [activeAggregatePowerForAllSources, activeAggregatePowerForEVs]);

  useEffect(() => {
    const historicalEVsConsumption = activeAggregatePowerForEVs.map((item) => ({
      timepoint: item.timepoint,
      value: item.value * 1.2
    }));
    const newChartDataEVs: EKZChartData = {
      consumptionValue: activeAggregatePowerForEVs,
      consumptionSiteValue: null,
      consumptionEVsValue: null,
      consumptionEVsHistoricalValue: historicalEVsConsumption,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: activeAggregatePowerForEVsPrediction,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    };
    setChartDataEVs(newChartDataEVs);
  }, [activeAggregatePowerForEVs, activeAggregatePowerForEVsPrediction]);

  useEffect(() => {
    const newChartDataDeterministicBaseline: EKZChartData = {
      consumptionValue: null,
      consumptionSiteValue: null,
      consumptionEVsValue: null,
      consumptionEVsHistoricalValue: null,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: deterministicBaseline
    };
    setChartDataDeterministicBaseline(newChartDataDeterministicBaseline);
  }, [deterministicBaseline]);

  return (
    <Grid container spacing={3} style={{ marginBottom: '6px' }}>
      <Grid sm={12}>
        <Chart
          data={chartDataEVs}
          unit={'MW'}
          headingText={'EV consumption and forecast'}
          nDecimals={1}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={true}
        />
      </Grid>
      <Grid sm={12}>
        <Chart
          data={chartDataAllSources}
          unit={'MW'}
          headingText={'Site and EV consumption'}
          nDecimals={1}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={true}
        />
      </Grid>
      <Grid sm={12}>
        <Chart
          data={chartDataDeterministicBaseline}
          unit={'%'}
          headingText={'Baseline charging schedule'}
          nDecimals={0}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={false}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardTuya;
