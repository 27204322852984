import { Box } from '@mui/material';
import TopBar from './TopBar';
import { Site } from '../types';
import { ReactNode } from 'react';
import Sidebar, { drawerWidth } from './Sidebar';

interface LayoutProps {
  children: ReactNode;
  sites: Site[];
}

const Layout: React.FC<LayoutProps> = ({ children, sites }) => {
  return (
    <>
      <Sidebar />
      <TopBar />
      <Box
        sx={{
          paddingTop: '100px', // The size of the top bar + gap
          paddingBottom: '35px',
          paddingLeft: `calc(${drawerWidth} + 2%)`,
          paddingRight: { xs: 0, sm: '2%' }
        }}
        className="main-container"
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
