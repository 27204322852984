import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import appAuth from '../initializedFirebase';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { grey } from '@mui/material/colors';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showSignInError, setShowSignInError] = useState(false);

  useEffect(() => {
    onAuthStateChanged(appAuth, async (user) => {
      if (user) {
        // User signed in (either just now through the button, or previously)
        navigate('/dashboard');
      }
    });
  }, [navigate]);

  const onLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signInWithEmailAndPassword(appAuth, email, password).catch((error) => {
      setShowSignInError(true);
    });
  };

  return (
    <div style={{ backgroundColor: '#fff', height: '100vh' }}>
      <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
          <img src="/logo_blue_nobckng.png" alt="Logo" style={{ height: '58px', width: '156px' }} />
        </div>

        <Box
          component="form"
          sx={{
            border: 1,
            borderColor: grey[400],
            borderRadius: 1,
            backgroundColor: '#f6f8fa',
            padding: 2,
            width: '35ch',
            '& .MuiTextField-root': { margin: 1 }
          }}
        >
          <Stack
            spacing={2}
            style={{ alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}
          >
            <TextField
              style={{ width: '100%', backgroundColor: '#fff' }}
              required
              id="login-form-email"
              label="Email address"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              style={{ width: '100%', backgroundColor: '#fff' }}
              required
              id="login-form-password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            {showSignInError && (
              <Typography variant="body1" color={'red'}>
                Incorrect user or password!
              </Typography>
            )}
            <Button
              style={{ width: '100%', backgroundColor: '#002161' }}
              variant="contained"
              onClick={onLogin}
            >
              Sign in
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: grey[400],
            borderRadius: 1,
            padding: 2,
            width: '35ch'
          }}
        >
          <Stack direction={'row'} spacing={1} style={{ justifyContent: 'center' }}>
            <div>No account yet?</div>
            <Link
              style={{ color: '#002161' }}
              underline="hover"
              href="mailto:contact@tilt-energy.com"
              onClick={(e) => {
                window.location.href = 'mailto:contact@tilt-energy.com';
                e.preventDefault();
              }}
            >
              Contact us
            </Link>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
};

export default Login;
