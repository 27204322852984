import React, { useRef, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Event } from '../pages/DataValues';
import { formatDate, formatTime, calculateDuration } from '../util';

interface TableProps {
  events: Event[];
}

const EventsTable: React.FC<TableProps> = ({ events }) => {
  const tableEl = useRef<HTMLDivElement>(null);
  const headerCellStyle = { backgroundColor: 'rgb(225, 225, 225)' };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page
  };

  return (
    <Paper sx={{ overflow: 'hidden', margin: '16px auto' }}>
      <TableContainer ref={tableEl} style={{ overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={headerCellStyle}>
                <b>Date</b>
              </TableCell>
              <TableCell align="center" style={headerCellStyle}>
                <b>Start time</b>
              </TableCell>
              <TableCell align="center" style={headerCellStyle}>
                <b>Duration</b>
              </TableCell>
              <TableCell align="center" style={headerCellStyle}>
                <b>Number of sites</b>
              </TableCell>
              <TableCell align="center" style={headerCellStyle}>
                <b>Total kW</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((event) => (
              <TableRow key={event.market_event_id} hover={true}>
                <TableCell align="center">{formatDate(event.start_at)}</TableCell>
                <TableCell align="center">{formatTime(event.start_at)}</TableCell>
                <TableCell align="center">
                  {calculateDuration(event.start_at, event.end_at)}
                </TableCell>
                <TableCell align="center">{event.n_invitations}</TableCell>
                <TableCell align="center">{event.power_impact}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={events.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default EventsTable;
