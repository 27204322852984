import React from 'react';
import { Paper, Stack, TextField } from '@mui/material';

import { SmallCard } from './TiltCard';
import { Site } from '../types';

interface TableProps {
  site: Site;
}

const SiteInfo: React.FC<TableProps> = ({ site }) => {
  return (
    <SmallCard>
      <Stack spacing={2} alignItems="flex-start">
        <Paper elevation={0} sx={{ typography: 'h6' }}>
          Site {site.site_id} details
        </Paper>
        <TextField
          id="site-city"
          label="City"
          value={site.city}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          id="site-address"
          label="Address"
          value={site.address}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          id="site-postcode"
          label="Postcode"
          value={site.postcode}
          InputProps={{
            readOnly: true
          }}
        />
        {site.peak_power && (
          <TextField
            id="site-peak-power"
            label="Peak installed power"
            value={site.peak_power}
            InputProps={{
              readOnly: true
            }}
          />
        )}
      </Stack>
    </SmallCard>
  );
};

export default SiteInfo;
