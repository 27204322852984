import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Site } from '../types';

interface TableProps {
  sites: Site[];
  setHighlightedSite: React.Dispatch<React.SetStateAction<number | null>>;
}

const SitesTable: React.FC<TableProps> = ({ sites, setHighlightedSite }) => {
  const tableEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [siteIndex, setSiteIndex] = useState<number>(0);
  const [distanceBottom, setDistanceBottom] = useState(0);
  const sitesIncrement = 20;
  const displayedSites = sites.slice(0, siteIndex + sitesIncrement);
  const hasMore = sites.length >= siteIndex + sitesIncrement;

  const loadMore = useCallback(() => {
    setSiteIndex((prevSiteIndex) => prevSiteIndex + sitesIncrement);
  }, []);

  const scrollListener = useCallback(() => {
    const tableRef = tableEl.current;
    if (tableRef) {
      let bottom = tableRef.scrollHeight - tableRef.clientHeight;

      if (!distanceBottom) {
        setDistanceBottom(Math.round(bottom * 0.2));
      }
      if (tableRef.scrollTop > bottom - distanceBottom && hasMore) {
        loadMore();
      }
    }
  }, [hasMore, loadMore, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    if (tableRef) {
      tableRef.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableRef) {
        tableRef.removeEventListener('scroll', scrollListener);
      }
    };
  }, [scrollListener]);

  return (
    <TableContainer ref={tableEl} style={{ height: '700px', overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <b>Site ID</b>
            </TableCell>
            <TableCell align="center">
              <b>City</b>
            </TableCell>
            <TableCell align="center">
              <b>Postcode</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedSites.map((site) => (
            <TableRow
              key={site.site_id}
              hover={true}
              onMouseEnter={() => setHighlightedSite(site.site_id)}
              onMouseLeave={() => setHighlightedSite(null)}
              onClick={() => navigate(`/site/${site.site_id}/details`)}
            >
              <TableCell align="center">{site.site_id}</TableCell>
              <TableCell align="center">{site.city}</TableCell>
              <TableCell align="center">{site.postcode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SitesTable;
