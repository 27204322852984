import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const BigCard = styled(Paper)(({ theme }) => ({
  height: '600px',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    height: '100%'
  }
}));

export const SmallCard = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1.3),
  paddingRight: theme.spacing(2.1),
  paddingBottom: theme.spacing(2.1),
  paddingLeft: theme.spacing(2.1),
  height: '100%'
}));
