export const levelTheming: {
  [level: string]: { iconColor: string; textColor: string; bgcolor: string };
} = {
  neutral: {
    iconColor: '#071A51',
    textColor: '#071A51',
    bgcolor: '#F8F9FF'
  },
  ineligible: {
    iconColor: '#ff4d4d',
    textColor: '#8b0000',
    bgcolor: '#ffd5d5ba'
  },
  warning: {
    iconColor: '#FFA500',
    textColor: '#FFA500',
    bgcolor: '#FFF4E1'
  },
  error: {
    iconColor: '#ff4d4d',
    textColor: '#8b0000',
    bgcolor: '#ffd5d5ba'
  },
  normal: {
    iconColor: '#23dac8',
    textColor: '#18978a',
    bgcolor: '#24dac830'
  },
  removed: {
    iconColor: '#FFA500',
    textColor: '#FFA500',
    bgcolor: '#FFF4E1'
  }
};

export const DSOTheming: { [level: string]: { textColor: string; bgcolor: string } } = {
  All: {
    textColor: '#071A51',
    bgcolor: '#F8F9FF'
  },
  'No Data': {
    textColor: '#8b0000',
    bgcolor: '#ffd5d5ba'
  },
  connected: {
    textColor: '#18978a',
    bgcolor: '#24dac830'
  },
  inProgress: {
    textColor: '#5b5959',
    bgcolor: '#ebebebba'
  }
};
