import React from 'react';
import Paper from '@mui/material/Paper';
import { dateToTimestamp, getCurrentTimestamp, formatTime, calculateDuration } from '../../util';
import {
  MeasurementValue,
  Prediction,
  Event,
  Invitation,
  BaselineValue,
  EKZChartData
} from '../../pages/DataValues';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import CardTitle from '../tuya/CardTitle';
import { CircularProgress } from '@mui/material';

// Initialize pattern fill module
patternFill(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: '' // No space between thousands when displaying numbers
  }
});

const PB_LINE_SPACE = 8.5;
const plotBandPattern = () => ({
  pattern: {
    path: {
      // Coordinates of the pattern to be used inside plot bands to display events and invitations.
      // Generated with ChatGPT.
      d:
        `M ${PB_LINE_SPACE * 2} 0 L 0 ${PB_LINE_SPACE * 2} M ${PB_LINE_SPACE * 2} ` +
        `${PB_LINE_SPACE} L ${PB_LINE_SPACE} ${PB_LINE_SPACE * 2} M ${PB_LINE_SPACE} ` +
        `0 L 0 ${PB_LINE_SPACE}`,
      stroke: 'color(srgb 0.1343 0.79 0.7272 / 0.3)', // green
      strokeWidth: 1
    },
    width: PB_LINE_SPACE * 2,
    height: PB_LINE_SPACE * 2,
    backgroundColor: 'color(srgb 0.1343 0.79 0.7272 / 0.065)' // green
  }
});

function getChartDataProperty(
  data: EKZChartData,
  property: string
): MeasurementValue[] | Prediction[] | Event[] | Invitation[] | null {
  const key = property as keyof EKZChartData;

  return data[key];
}

function isMeasurementValue(item: any): item is MeasurementValue {
  return (item as MeasurementValue).timepoint !== undefined;
}

function isPrediction(item: any): item is Prediction {
  return (item as Prediction).predicted_for !== undefined;
}

function isBaselineValue(item: any): item is BaselineValue {
  return (item as BaselineValue).timepoint !== undefined;
}

interface ChartProps {
  data: EKZChartData;
  unit: string;
  headingText: string;
  nDecimals: number;
  timeRangeButtons: any[];
  shareTooltip: boolean;
}

interface PlotBand {
  color: ReturnType<typeof plotBandPattern>;
  from: number;
  to: number;
  events: {
    mouseover: (e: any) => void;
    mouseout: (e: any) => void;
  };
}

const Chart: React.FC<ChartProps> = ({
  data,
  unit,
  headingText,
  nDecimals,
  timeRangeButtons,
  shareTooltip
}) => {
  const seriesTypes: Record<string, any> = {
    consumption: {
      color: '#002161'
    },
    consumptionSite: {
      color: '#cde3ff'
    },
    consumptionEVs: {
      color: '#002161'
    },
    consumptionEVsHistorical: {
      color: '#24DBC9'
    },
    production: {
      color: '#F3C13AB0'
    },
    flexibility: {
      color: '#a83271'
    },
    baseline: {
      color: '#40BC0A'
    }
  };

  function formatOrderOfMagnitude(number: number, orderForFitToDataDynamically: number) {
    const units = ['W', 'kW', 'MW', 'GW', 'TW', 'PW'];
    let value = number;
    if (units.includes(unit)) {
      let order = units.indexOf(unit);
      value = number / Math.pow(10, order * 3);
    } else if (unit === 'fitToDataDynamically') {
      value = number / Math.pow(10, orderForFitToDataDynamically * 3);
      unit = units[orderForFitToDataDynamically];
    } else if (unit === 'arbitrary') {
      value = number;
      unit = '';
    }

    return value;
  }

  function processBaselineData(valueType: keyof typeof seriesTypes) {
    let values: [number, number][] = [];
    const valuesKey = valueType + 'Value';
    const rawValues = getChartDataProperty(data, valuesKey);
    if (rawValues !== null) {
      values = rawValues.map((item) => {
        if (!isBaselineValue(item)) {
          throw new Error('Item is not a BaselineValue');
        }
        if (unit === 'arbitrary') {
          unit = '';
        }
        return [dateToTimestamp(item.timepoint), item.value * 100];
      });
    }
    return values;
  }

  function processMeasurementData(valueType: keyof typeof seriesTypes) {
    let values: [number, number][] = [];
    const valuesKey = valueType + 'Value';
    const rawValues = getChartDataProperty(data, valuesKey);
    if (rawValues !== null) {
      values = rawValues.map((item) => {
        if (!isMeasurementValue(item)) {
          throw new Error('Item is not a MeasurementValue');
        }
        if (unit === 'arbitrary') {
          unit = '';
        }
        const formattedValue = formatOrderOfMagnitude(item.value, 0);
        return [dateToTimestamp(item.timepoint), formattedValue];
      });
    }
    return values;
  }

  function processSeries(valueType: keyof typeof seriesTypes) {
    // Format values for Highcharts
    let values: [number, number][] = [];
    const valuesKey = valueType + 'Value';
    const rawValues = getChartDataProperty(data, valuesKey);
    if (rawValues !== null) {
      const maxValue = Math.max(
        ...rawValues.map((item) => {
          if (!isMeasurementValue(item)) {
            throw new Error('Item is not a MeasurementValue');
          }
          return item.value;
        })
      );
      const orderForFitToDataDynamically = Math.floor(Math.log10(Math.abs(maxValue)) / 3);
      values = rawValues.map((item) => {
        if (!isMeasurementValue(item)) {
          throw new Error('Item is not a MeasurementValue');
        }
        const formattedValue = formatOrderOfMagnitude(item.value, orderForFitToDataDynamically);
        return [dateToTimestamp(item.timepoint), formattedValue];
      });
    }

    // Extend the final value horizontally by duplicating it with an adjusted timestamp.
    if (values.length >= 2) {
      const lastValue = values[values.length - 1];
      const penultimateValue = values[values.length - 2];
      const lastInterval = lastValue[0] - penultimateValue[0];
      values.push([lastValue[0] + lastInterval, lastValue[1]]);
    }

    // Format forecast for Highcharts
    let forecast: [number, number][] = [];
    const forecastKey = valueType + 'Forecast';
    const rawForecast = getChartDataProperty(data, forecastKey);
    if (rawForecast !== null) {
      forecast = rawForecast.map((item) => {
        if (!isPrediction(item)) {
          throw new Error('Item is not a Prediction');
        }
        const formattedValue = formatOrderOfMagnitude(item.value, 0);
        return [dateToTimestamp(item.predicted_for), formattedValue];
      });

      // Filter out forecast data points that are anterior to the latest measurement value
      if (values.length > 0) {
        const latestValueTimepoint: number = values[values.length - 1][0];
        forecast = forecast.filter((item: [number, number]) => item[0] > latestValueTimepoint);
      }
    }

    // Extend the final forecast value horizontally by duplicating it with an adjusted timestamp.
    if (forecast.length >= 2) {
      const lastForecast = forecast[forecast.length - 1];
      const penultimateForecast = forecast[forecast.length - 2];
      const lastInterval = lastForecast[0] - penultimateForecast[0];
      forecast.push([lastForecast[0] + lastInterval, lastForecast[1]]);
    }

    // Missing link between values and forecast
    let transition: [number, number][] = [];
    if (values.length > 0 && forecast.length > 0) {
      transition = [values[values.length - 1], forecast[0]];
    }

    return {
      values,
      forecast,
      transition
    };
  }

  function getHighchartsSeries() {
    let series: any[] = [];
    Object.keys(seriesTypes).forEach((key) => {
      if (key === 'baseline') {
        let processedBaselineData = processBaselineData('baseline');

        series.push({
          name: 'Baseline charging schedule',
          data: processedBaselineData,
          color: seriesTypes[key]['color'],
          step: true,
          showInNavigator: true,
          showInLegend: processedBaselineData.length > 0 ? true : false,
          dataGrouping: { enabled: false },
          stickyTracking: false
        });
      } else if (
        key === 'consumptionSite' ||
        key === 'consumptionEVs' ||
        key === 'consumptionEVsHistorical'
      ) {
        let processedData = processMeasurementData(key);

        if (key === 'consumptionEVsHistorical') {
          series.push({
            name: 'consumption (forecast historical)',
            data: processedData,
            color: seriesTypes[key]['color'],
            step: true,
            showInNavigator: true,
            showInLegend: processedData.length > 0 ? true : false,
            dataGrouping: { enabled: false },
            stickyTracking: false
          });
        } else if (key === 'consumptionSite') {
          // values
          series.push({
            name: 'Site consumption',
            data: processedData,
            color: seriesTypes[key]['color'],
            step: true,
            showInNavigator: true,
            showInLegend: processedData.length > 0 ? true : false,
            dataGrouping: { enabled: false },
            stickyTracking: false
          });
        } else if (key === 'consumptionEVs') {
          // values
          series.push({
            name: 'EV consumption',
            data: processedData,
            color: seriesTypes[key]['color'],
            step: true,
            showInNavigator: true,
            showInLegend: processedData.length > 0 ? true : false,
            dataGrouping: { enabled: false },
            stickyTracking: false
          });
        }
      } else {
        let processedData = processSeries(key);

        // values
        series.push({
          name: key,
          data: processedData.values,
          color: seriesTypes[key]['color'],
          step: true,
          showInNavigator: true,
          showInLegend: processedData.values.length > 0 ? true : false,
          dataGrouping: { enabled: false },
          stickyTracking: false
        });

        // transition between values and forecast
        series.push({
          name: null,
          data: processedData.transition,
          dashStyle: 'ShortDot',
          color: seriesTypes[key]['color'],
          step: true,
          enableMouseTracking: false, // No tooltip to avoid label duplicates
          showInNavigator: true,
          showInLegend: false,
          dataGrouping: { enabled: false },
          stickyTracking: false
        });

        // forecast
        series.push({
          name: key + ' (forecast)',
          data: processedData.forecast,
          dashStyle: 'ShortDot',
          color: '#24DBC9',
          step: true,
          showInNavigator: true,
          showInLegend: processedData.forecast.length > 0 ? true : false,
          dataGrouping: { enabled: false },
          stickyTracking: false
        });
      }
    });

    return series;
  }

  function getToolTipElement(mouseEvent: Highcharts.PointerEventObject) {
    // Return the tooltip element of the current chart
    try {
      const tooltip =
        // @ts-ignore
        mouseEvent.fromElement?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.querySelector(
          '.tooltip-event'
        );
      return tooltip;
    } catch (error) {
      console.error("Can't select tooltip from mouse event:", error);
      return null;
    }
  }

  function getPlotBand(dateStart: string, dateEnd: string, label: string): PlotBand {
    // Returns a plotband representing an event or an invitation
    const timestampStart = dateToTimestamp(dateStart);
    const timestampEnd = dateToTimestamp(dateEnd);

    const plotBand = {
      color: plotBandPattern(),
      from: timestampStart,
      to: timestampEnd,
      events: {
        mouseover: function (e: any) {
          let tooltip = getToolTipElement(e);
          if (tooltip !== null) {
            try {
              tooltip.innerHTML = label;
              tooltip.style.display = 'block';
              tooltip.style.left = e.pageX + 20 + 'px';
              tooltip.style.top = e.pageY - tooltip.offsetHeight - 10 + 'px';
            } catch (error) {
              console.error("Can't display tooltip:", error);
              return null;
            }
          }
        },
        mouseout: function (e: any) {
          let tooltip = getToolTipElement(e);
          if (tooltip !== null) {
            try {
              tooltip.style.display = 'none';
            } catch (error) {
              console.error("Can't hide tooltip:", error);
              return null;
            }
          }
        }
      }
    };

    return plotBand;
  }

  function processEvent(event: Event) {
    const startTime = formatTime(event.start_at);
    const duration = calculateDuration(event.start_at, event.end_at);
    const label = `<h4>Demand response event</h4>
                   <p>Start time: ${startTime}</p>
                   <p>Duration: ${duration}</p>
                   <p>Sites: ${event.n_invitations}</p>`;
    const plotBand = getPlotBand(event.start_at, event.end_at, label);
    return plotBand;
  }

  function processInvitation(invitation: Invitation) {
    const startTime = formatTime(invitation.event_starts_at);
    const duration = calculateDuration(invitation.event_starts_at, invitation.event_ends_at);
    let label = `<h4>Demand response event</h4>
                 <p>Start time: ${startTime}</p>`;
    if (invitation.duration !== null) {
      label += `<p>Duration: ${duration}</p>`;
    }
    if (invitation.has_participated !== null) {
      label += `<p>Participation: ${invitation.has_participated}</p>`;
    }
    const plotBand = getPlotBand(invitation.event_starts_at, invitation.event_ends_at, label);
    return plotBand;
  }

  function getChart() {
    const series = getHighchartsSeries();
    const currentTimestamp = getCurrentTimestamp();

    if (
      data.events !== null &&
      data.events.length > 0 &&
      data.invitations !== null &&
      data.invitations.length > 0
    ) {
      console.error("Error: chart can't have both events and invitations");
    }

    let plotBands: PlotBand[] = [];
    if (data.events !== null && data.events.length > 0) {
      plotBands = data.events.map((item) => processEvent(item));
    } else if (data.invitations !== null && data.invitations.length > 0) {
      plotBands = data.invitations.map((item) => processInvitation(item));
    }

    return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={{
          series: series,
          chart: {
            height: 420
          },
          legend: {
            enabled: true
          },
          yAxis: {
            labels: {
              format: '{value} ' + unit
            }
          },
          rangeSelector: {
            allButtonsEnabled: false,
            inputStyle: {
              color: 'rgb(102, 102, 102)' // grey
            },
            buttons: timeRangeButtons
          },
          xAxis: {
            plotBands: plotBands,

            // Display the current time
            plotLines: [
              {
                color: 'rgb(210, 210, 210)', // grey
                width: 2.5,
                value: currentTimestamp,
                label: {
                  text: 'Now',
                  rotation: 0,
                  align: 'center',
                  x: 0,
                  y: -5,
                  style: {
                    color: 'rgb(102, 102, 102)'
                  }
                }
              }
            ]
          },
          tooltip: {
            valueSuffix: ' ' + unit,
            valueDecimals: nDecimals,
            split: false,
            shared: shareTooltip
          },
          credits: { enabled: false }
        }}
      />
    );
  }

  const hasData = Object.values(data).some((value) => value !== null && value.length > 0);

  return (
    <Paper sx={{ padding: 2 }}>
      <div style={{ marginBottom: '10px' }}>
        <CardTitle title={headingText} />
      </div>
      <div className="tooltip-event"></div>
      {hasData ? getChart() : <CircularProgress />}
    </Paper>
  );
};

export default Chart;
