import { useState, useEffect } from 'react';
import { Chip } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';

const chipConfig = {
  neutral: {
    textColor: '#071A51',
    style: {
      active: {
        bgcolor: '#F8F9FF'
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  'No Data': {
    icon: <Error />,
    iconColor: '#ff4d4d',
    textColor: '#8b0000',
    style: {
      active: {
        bgcolor: '#ffd5d5ba'
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  connected: {
    icon: <CheckCircle />,
    iconColor: '#23dac8',
    textColor: '#18978a',
    style: {
      active: {
        bgcolor: '#24dac830'
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  inProgress: {
    icon: <CachedIcon />,
    iconColor: '#bab5b5',
    textColor: '#5b5959',
    style: {
      active: {
        bgcolor: '#ebebebba'
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  }
};

const DSOStatusFilterChip = ({ active, label, level, count, handleClick }) => {
  const [bgcolor, setBgcolor] = useState(undefined);

  useEffect(() => {
    if (active === true) {
      const { bgcolor } = chipConfig[level].style.active;
      setBgcolor(bgcolor);
    } else {
      const { bgcolor } = chipConfig[level].style.inactive;
      setBgcolor(bgcolor);
    }
  }, [active, label, level]);

  const { icon, iconColor, textColor } = chipConfig[level] || chipConfig.noData;

  return (
    <Chip
      icon={icon}
      label={label + ' (' + count[label] + ')'}
      sx={{
        bgcolor: bgcolor,
        color: textColor,
        marginRight: '10px',
        fontSize: '18px',
        padding: '20px',
        '& .MuiChip-icon': {
          color: iconColor,
          fontSize: '22px',
          marginRight: '-8px'
        }
      }}
      onClick={() => handleClick(label)}
    />
  );
};

export default DSOStatusFilterChip;
