import { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Stack, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from 'react-router-dom';
import appAuth from '../initializedFirebase';
import { signOut } from 'firebase/auth';
import { drawerWidth } from './Sidebar';

const TopBar = () => {
  const [pathText, setPathText] = useState('');
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  useEffect(() => {
    // Set the text on the top bar
    const path = location.pathname;
    let text = '';
    if (path === '/') {
      text = 'Dashboard';
    } else if (path.startsWith('/site/') && path.endsWith('details')) {
      text = ``;
    } else if (path.startsWith('/site/') && path.includes('device')) {
      const regex = /\/device\/(\d+)/;
      const matches = path.match(regex);
      const deviceId = matches && matches[1];
      text = `Device ${deviceId}`;
    }

    setPathText(text);
  }, [location.pathname]);

  const handlePopoverOpen = (event: React.MouseEvent<any>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const onLogout = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    signOut(appAuth).catch((error) => {
      console.log(error);
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: 'info.main',
        width: `calc(100% - ${drawerWidth})`,
        ml: `${drawerWidth}px`
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#000'
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography variant="h4" noWrap>
            {pathText}
          </Typography>
        </div>

        <div style={{ flex: 1 }}></div>

        <div style={{ flex: 0.5 }}>
          <Stack direction="row" alignItems="center" spacing={0.25}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer', userSelect: 'none' }}
              draggable="false"
              onClick={handlePopoverOpen}
            >
              <Typography variant="h6" noWrap>
                {localStorage.getItem('user_name')}
              </Typography>
              <ExpandMoreIcon />
            </Box>
            <Popover
              open={Boolean(popoverAnchorEl)}
              anchorEl={popoverAnchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top', // Popover grows downwards from the top
                horizontal: 'left'
              }}
              sx={{ marginTop: '3px' }}
            >
              <Box
                sx={{ p: '0.45rem 3rem 0.4rem 0.7rem', cursor: 'pointer', userSelect: 'none' }}
                draggable="false"
                onClick={onLogout}
              >
                <Typography sx={{ display: 'flex', alignItems: 'center' }} noWrap>
                  Logout
                  <LogoutIcon sx={{ fontSize: '19px', p: '0rem 0 1.6px 0', marginLeft: '4.5px' }} />
                </Typography>
              </Box>
            </Popover>
          </Stack>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
