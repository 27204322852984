import { Site } from '../types';
import React, { ReactElement, useEffect, useState } from 'react';
import qs from 'qs';
import { MeasurementValue, Prediction, Event, ChartData } from './DataValues';
import Chart from '../components/Chart';
import { getDates, scaleValues, scalePredictions } from '../util';
import axios from 'axios';
import { Avatar, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PlaceIcon from '@mui/icons-material/Place';
import RouterIcon from '@mui/icons-material/Router';
import EuroIcon from '@mui/icons-material/Euro';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
interface DashboardProps {
  serverUrl: string;
  sites: Site[];
}

const Dashboard: React.FC<DashboardProps> = ({ sites, serverUrl }) => {
  const [consumptionData, setConsumptionData] = useState<MeasurementValue[]>([]);
  const [productionData, setProductionData] = useState<MeasurementValue[]>([]);
  const [consumptionForecast, setConsumptionForecast] = useState<Prediction[]>([]);
  const [productionForecast, setProductionForecast] = useState<Prediction[]>([]);
  const [consumptionWeekly, setConsumptionWeekly] = useState<MeasurementValue[]>([]);
  const [productionWeekly, setProductionWeekly] = useState<MeasurementValue[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [chartData, setChartData] = useState<ChartData>();
  const [weeklyChartData, setWeeklyChartData] = useState<ChartData>();

  useEffect(() => {
    const token = localStorage.getItem('user_token');

    const fetchConsumption = async () => {
      const { today, ereyesterday, thirdDay, oneYearAgo } = getDates();

      try {
        const valsResponse = await axios.get(`${serverUrl}/measurement_values_sum`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            device_name: 'Consumption',
            measurement_name: 'Consumption',
            start: ereyesterday,
            end: today
          }
        });
        // Convert values from W to kW
        const dataScaled = scaleValues(valsResponse.data, 0.001);

        setConsumptionData(dataScaled);
      } catch (error) {
        console.error('Error fetching consumption data:', error);
      }
      try {
        const predResponse = await axios.get(`${serverUrl}/measurement_predictions_sum`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            device_name: 'Consumption',
            measurement_name: 'Consumption',
            start: today,
            end: thirdDay
          }
        });

        // Convert values from W to kW
        const dataScaled = scalePredictions(predResponse.data, 0.001);

        setConsumptionForecast(dataScaled);
      } catch (error) {
        console.error('Error fetching consumption predictions:', error);
      }
      try {
        const valsResponse = await axios.get(`${serverUrl}/measurement_values_sum`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            device_name: 'Consumption',
            measurement_name: 'Consumption',
            start: oneYearAgo,
            end: today
          }
        });

        // Convert values from W to kW
        const dataScaled = scaleValues(valsResponse.data, 0.001);
        setConsumptionWeekly(dataScaled);
      } catch (error) {
        console.error('Error fetching weekly consumption data:', error);
      }
    };
    const fetchProduction = async () => {
      const { today, ereyesterday, thirdDay, oneYearAgo } = getDates();

      try {
        const valsResponse = await axios.get(`${serverUrl}/measurement_values_sum`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            device_name: 'Production',
            measurement_name: 'Production',
            start: ereyesterday,
            end: today
          }
        });

        // Convert values from W to kW
        const dataScaled = scaleValues(valsResponse.data, 0.001);

        setProductionData(dataScaled);
      } catch (error) {
        console.error('Error fetching production data:', error);
      }
      try {
        const predResponse = await axios.get(`${serverUrl}/measurement_predictions_sum`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            device_name: 'Production',
            measurement_name: 'Production',
            start: today,
            end: thirdDay
          }
        });

        // Convert values from W to kW
        const dataScaled = scalePredictions(predResponse.data, 0.001);

        setProductionForecast(dataScaled);
      } catch (error) {
        console.error('Error fetching production forecast:', error);
      }
      try {
        const valsResponse = await axios.get(`${serverUrl}/measurement_values_sum`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            device_name: 'Production',
            measurement_name: 'Production',
            start: oneYearAgo,
            end: today
          }
        });

        // Convert values from W to kW
        const dataScaled = scaleValues(valsResponse.data, 0.001);

        setProductionWeekly(dataScaled);
      } catch (error) {
        console.error('Error fetching weekly production data:', error);
      }
    };
    const fetchEvents = async () => {
      if (sites === undefined) {
        return;
      }

      const { thirdDay, oneYearAgo } = getDates();
      const siteIds = sites.map((site) => site.site_id);

      try {
        const eventsReponse = await axios.get(`${serverUrl}/events_result`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            site_ids: siteIds,
            start: oneYearAgo,
            end: thirdDay
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          }
        });

        setEvents(eventsReponse.data);
      } catch (error) {
        console.error('Error fetching events data:', error);
      }
    };
    if (token !== '') {
      fetchConsumption();
      fetchProduction();
      fetchEvents();
    }
  }, [serverUrl, sites]);

  useEffect(() => {
    // We create dummy flexibility forecast data by multipling the consumption forecast by 30%
    const flexibilityForecast = consumptionForecast.map((item) => ({
      predicted_for: item.predicted_for,
      value: item.value * 0.3
    }));
    const newChartData: ChartData = {
      consumptionValue: consumptionData,
      productionValue: productionData,
      flexibilityValue: null,
      consumptionForecast: consumptionForecast,
      productionForecast: productionForecast,
      flexibilityForecast: flexibilityForecast,
      events: events,
      invitations: null
    };
    setChartData(newChartData);
  }, [consumptionData, productionData, consumptionForecast, productionForecast, events]);

  useEffect(() => {
    const some_data_fetched: boolean = consumptionWeekly.length > 0 || productionWeekly.length > 0;

    if (some_data_fetched) {
      const aggChartData: ChartData = {
        consumptionValue: consumptionWeekly,
        productionValue: productionWeekly,
        flexibilityValue: null,
        consumptionForecast: null,
        productionForecast: null,
        flexibilityForecast: null,
        events: events,
        invitations: null
      };
      setWeeklyChartData(aggChartData);
    }
  }, [consumptionWeekly, productionWeekly, events]);

  if (sites === undefined || sites.length === 0) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '40px' }}
      >
        <CircularProgress />
      </Stack>
    );
  } else {
    return (
      <Grid container spacing={3} style={{ marginBottom: '6px' }}>
        <Grid sm={12}>{getSummaryNumbers(sites, events)}</Grid>
        <Grid sm={12}>
          {chartData && (
            <Chart
              data={chartData}
              unit={'kW'}
              headingText={'Recent consumption'}
              nDecimals={1}
              timeRangeButtons={[
                {
                  type: 'day',
                  count: 1,
                  text: '1d'
                },
                {
                  type: 'all',
                  text: 'All'
                }
              ]}
            />
          )}
        </Grid>
        <Grid sm={12}>
          {weeklyChartData && (
            <Chart
              data={weeklyChartData}
              unit={'kW'}
              headingText="Historical consumption"
              nDecimals={1}
              timeRangeButtons={[
                {
                  type: 'day',
                  count: 3,
                  text: '3d'
                },
                {
                  type: 'day',
                  count: 7,
                  text: '1w'
                },
                {
                  type: 'month',
                  count: 1,
                  text: '1m'
                },
                {
                  type: 'month',
                  count: 3,
                  text: '3m'
                },
                {
                  type: 'all',
                  text: 'All'
                }
              ]}
            />
          )}
        </Grid>
      </Grid>
    );
  }
};

function getSummaryNumbers(sites: Site[], events: Event[]) {
  const iconsSize = 33;

  return (
    <Paper sx={{ padding: 2 }}>
      <Stack direction={'row'} justifyContent="space-around" alignItems="center" spacing={0}>
        {getLabelledIcon(
          <PlaceIcon sx={{ fontSize: iconsSize }} />,
          'Number of sites',
          `${sites.length}`
        )}
        {getLabelledIcon(
          <RouterIcon sx={{ fontSize: iconsSize }} />,
          'Number of devices',
          `${sites.reduce((acc, site) => acc + site.n_devices, 0)}`
        )}
        {getLabelledIcon(
          <EventAvailableIcon sx={{ fontSize: iconsSize }} />,
          'Demand response events',
          `${events.length}`
        )}
        {getLabelledIcon(<EuroIcon sx={{ fontSize: iconsSize }} />, 'Revenue generated', '-')}
      </Stack>
    </Paper>
  );
}

function getLabelledIcon(icon: ReactElement, label: string, value: string) {
  const circlesSize = 56;

  return (
    <Stack direction={'column'} style={{ alignItems: 'center' }}>
      <Avatar sx={{ width: circlesSize, height: circlesSize, bgcolor: '#22c9b9D0' }}>{icon}</Avatar>
      <Typography sx={{ paddingTop: '5px' }}>{label}</Typography>
      <Typography variant="h5" sx={{ paddingTop: '2px' }}>
        <b>{value}</b>
      </Typography>
    </Stack>
  );
}

export default Dashboard;
