import React from 'react';
import { Device } from '../types';
import Chart from '../components/Chart';
import { ChartData } from './DataValues';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import DevicesInfo from '../components/DevicesInfo';

interface DeviceDashboardProps {
  chartData: ChartData | undefined;
  devices: Device[];
}

const DeviceDashboard: React.FC<DeviceDashboardProps> = ({ chartData, devices }) => {
  const { device_id } = useParams();
  const device: Device | undefined = getDevice();
  const filteredChartData: ChartData | undefined = getChartData();

  function getDevice(): Device | undefined {
    const id = Number(device_id);
    if (device_id === undefined || isNaN(id)) {
      return undefined;
    } else {
      return devices.find((device) => device.device_id === id);
    }
  }

  function getChartData(): ChartData | undefined {
    if (device === undefined || chartData === undefined) {
      return undefined;
    } else {
      if (device.name === 'Consumption') {
        return {
          ...chartData,
          productionValue: null,
          productionForecast: null
        };
      } else if (device.name === 'Production') {
        return {
          ...chartData,
          consumptionValue: null,
          consumptionForecast: null
        };
      } else {
        console.log('Invalid device name');
        return undefined;
      }
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid sm={4}>{device ? <DevicesInfo device={device} /> : null}</Grid>
      <Grid sm={12}>
        {filteredChartData ? (
          <Chart
            data={filteredChartData}
            unit={'W'}
            headingText="Recent consumption"
            nDecimals={0}
            timeRangeButtons={[
              {
                type: 'day',
                count: 1,
                text: '1d'
              },
              {
                type: 'all',
                text: 'All'
              }
            ]}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default DeviceDashboard;
