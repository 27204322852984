import { Stack } from '@mui/material';
import DisplayField from './DisplayField';
import CardTitle from './CardTitle';
import { SmallCard } from '../TiltCard';
import StatusChip from './StatusChip';

const MeterInfo = ({ site }) => {
  const meterStatus = site?.params?.meter_status || 'inProgress';
  const meterLastData = site?.params?.meter_last_data || '';

  return (
    <SmallCard>
      <Stack spacing={2} alignItems="flex-start">
        <CardTitle title="DSO meter" />
        <DisplayField fieldName="Status" fieldValue={<StatusChip status={meterStatus} />} />
        {meterLastData && <DisplayField fieldName="Last data" fieldValue={meterLastData} />}
      </Stack>
    </SmallCard>
  );
};

export default MeterInfo;
