import React, { useEffect, useState } from 'react';
import './App.css';
import './Charts.css';
import Sites from './pages/Sites';
import SitesTuya from './pages/tuya/Sites';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SiteDashboard from './pages/SiteDashboard';
import SiteDashboardTuya from './pages/tuya/SiteDashboard';
import EventsDashboard from './pages/EventsDashboard';
import { Site } from './types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Login from './pages/Login';
import Layout from './components/Layout';
import { onAuthStateChanged } from 'firebase/auth';
import appAuth from './initializedFirebase';
import Dashboard from './pages/Dashboard';
import DashboardTuya from './pages/tuya/Dashboard';
import DashboardEKZ from './pages/ekz/Dashboard';
import { getSites } from './requests';

const theme = createTheme({
  typography: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      // shades of blue/indigo
      light: '#017EFA',
      main: '#1976d2', // default
      dark: '#081A51'
    },
    secondary: {
      // shades of green
      main: '#30D987'
    },
    background: {
      default: '#F8F9FF'
    },
    info: {
      main: '#fff' // white
    }
  }
});

const App: React.FC = () => {
  const navigate = useNavigate();
  const [sites, setSites] = useState<Site[]>([]);
  const [token, setToken] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');

  const serverUrl = 'https://tilt-api-server-3rihgzpwea-od.a.run.app';
  // const serverUrl = 'http://localhost:8003';

  useEffect(() => {
    onAuthStateChanged(appAuth, async (user) => {
      if (user) {
        const uToken = await user.getIdToken();
        setToken(uToken);
        localStorage.setItem('user_token', uToken);

        const uName = user.email ? user.email.split('@')[0] : 'user';
        const uNameCapitalized = uName.charAt(0).toUpperCase() + uName.slice(1);
        localStorage.setItem('user_name', uNameCapitalized);

        setUserEmail(user.email || '');
      } else {
        // User is signed out
        localStorage.setItem('user_token', '');
        localStorage.setItem('user_name', '');
        setSites([]);
        setToken('');
        setUserEmail('');
        navigate('/'); // Login again
      }
    });
  }, [navigate]);

  useEffect(() => {
    const fetchSites = async () => {
      if (token !== '' && token !== null) {
        const siteData = await getSites(serverUrl, token);
        setSites(siteData);
      }
    };

    fetchSites();
  }, [setSites, serverUrl, token]);

  let SelectedSitesComponent = undefined;
  let SelectedSitesDashboard = undefined;
  let SelectedDashboard = undefined;

  if (
    userEmail === 'tiltaccess@deltadore.com' ||
    userEmail === 'bioenergies@tilt-energy.com' ||
    userEmail === 'cdc@tilt-energy.com' ||
    userEmail === 'ja.bon@waat.fr' ||
    userEmail === 'olivier.penichout@icade.fr' ||
    userEmail === 'zeplug@tilt-energy.com' ||
    userEmail === 'alliander@tilt-energy.com' ||
    userEmail === 'sensinov@tilt-energy.com' ||
    userEmail === 'spirit-energies@tilt-energy.com' ||
    userEmail === 'schneider@tilt-energy.com'
  ) {
    SelectedSitesComponent = Sites;
    SelectedSitesDashboard = SiteDashboard;
    SelectedDashboard = Dashboard;
  } else if (userEmail === 'demo@tilt-energy.com') {
    SelectedSitesComponent = SitesTuya;
    SelectedSitesDashboard = SiteDashboard;
    SelectedDashboard = Dashboard;
  } else if (userEmail === 'ekz@tilt-energy.com') {
    SelectedSitesComponent = Sites;
    SelectedSitesDashboard = SiteDashboard;
    SelectedDashboard = DashboardEKZ;
  } else {
    SelectedSitesComponent = SitesTuya;
    SelectedSitesDashboard = SiteDashboardTuya;
    SelectedDashboard = DashboardTuya;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <Layout sites={sites}>
              <SelectedDashboard sites={sites} serverUrl={serverUrl} />
            </Layout>
          }
        />
        {userEmail !== 'ekz@tilt-energy.com' && (
          <>
            <Route
              path="/events"
              element={
                <Layout sites={sites}>
                  <EventsDashboard sites={sites} serverUrl={serverUrl} />
                </Layout>
              }
            />
            <Route
              path="/sites"
              element={
                <Layout sites={sites}>
                  <SelectedSitesComponent sites={sites} serverUrl={serverUrl} />
                </Layout>
              }
            />
            <Route
              path="/site/:site_id/*"
              element={
                <Layout sites={sites}>
                  <SelectedSitesDashboard serverUrl={serverUrl} />
                </Layout>
              }
            />
          </>
        )}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
