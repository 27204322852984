import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { SmallCard } from '../TiltCard';
import CardTitle from './CardTitle';
import NewStatusChip from './NewStatusChip';

const DevicesTable = ({ devices = [], siteInfo }) => {
  const cellStyle = { paddingY: '9px' };

  return (
    <SmallCard>
      <CardTitle title="Devices" />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={cellStyle}>
              <b>ID</b>
            </TableCell>
            <TableCell sx={cellStyle}>
              <b>Name</b>
            </TableCell>
            <TableCell sx={cellStyle}>
              <b>Status</b>
            </TableCell>
            <TableCell sx={cellStyle}>
              <b>Product</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.length === 0 ? (
            <TableRow></TableRow>
          ) : (
            devices
              .filter((device) => {
                if (device['type'] === 'DSO Meter') {
                  return false;
                }
                return true;
              })
              .map((device) => (
                <TableRow key={device.device_id} hover={true}>
                  <TableCell sx={cellStyle}>{device['external_id']}</TableCell>
                  <TableCell sx={cellStyle}>{device['name']}</TableCell>
                  <TableCell sx={cellStyle}>
                    <NewStatusChip
                      label={device['params']['status']['label']}
                      level={device['params']['status']['level']}
                    />
                  </TableCell>
                  <TableCell sx={cellStyle}>{device['params']['product_name']}</TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>
    </SmallCard>
  );
};

export default DevicesTable;
