import { Chip } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';

import ReportIcon from '@mui/icons-material/Report';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { levelTheming } from '../../colors';

const NewStatusChip = ({ label, level }) => {
  const chipConfig = {
    ineligible: {
      icon: <ReportIcon />,
      iconColor: levelTheming.ineligible.iconColor,
      textColor: levelTheming.ineligible.textColor,
      bgcolor: levelTheming.ineligible.bgcolor
    },
    warning: {
      icon: <WarningRoundedIcon />,
      iconColor: levelTheming.warning.iconColor,
      textColor: levelTheming.warning.textColor,
      bgcolor: levelTheming.warning.bgcolor
    },
    error: {
      icon: <Error />,
      iconColor: levelTheming.error.iconColor,
      textColor: levelTheming.error.textColor,
      bgcolor: levelTheming.error.bgcolor
    },
    normal: {
      icon: <CheckCircle />,
      iconColor: levelTheming.normal.iconColor,
      textColor: levelTheming.normal.textColor,
      bgcolor: levelTheming.normal.bgcolor
    },
    removed: {
      icon: <RemoveCircleIcon />,
      iconColor: levelTheming.removed.iconColor,
      textColor: levelTheming.removed.textColor,
      bgcolor: levelTheming.removed.bgcolor
    }
  };

  const { icon, bgcolor, iconColor, textColor } = chipConfig[level] || chipConfig.noData;

  return (
    <Chip
      icon={icon}
      label={label}
      sx={{
        bgcolor: bgcolor,
        color: textColor,
        '& .MuiChip-icon': {
          color: iconColor,
          fontSize: '22px',
          marginRight: '-10px',
          marginLeft: '10px'
        }
      }}
    />
  );
};

export default NewStatusChip;
