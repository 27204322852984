import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { SmallCard } from './TiltCard';
import { Device } from '../types';

interface TableProps {
  devices: Device[];
}

const DevicesTable: React.FC<TableProps> = ({ devices }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={SmallCard}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Device ID</b>
            </TableCell>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Type</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((device) => (
            <TableRow
              key={device.device_id}
              hover={true}
              onClick={() => navigate(`/site/${device.site_id}/device/${device.device_id}`)}
            >
              <TableCell>{device.device_id}</TableCell>
              <TableCell>{device.name}</TableCell>
              <TableCell>{device.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevicesTable;
