import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { CircularProgress, Stack, Typography } from '@mui/material';
import SitesTable from '../../components/tuya/SitesTable';
import { BigCard } from '../../components/TiltCard';
import { calculateMapCenter } from '../../components/Map';
import { Site } from '../../types';

interface SitesProps {
  serverUrl: string;
  sites: Site[];
}

const SitesTuya: React.FC<SitesProps> = ({ sites, serverUrl }) => {
  const [center, setCenter] = useState<[number, number] | null>(null);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [highlightedSite, setHighlightedSite] = useState<number | null>(null);

  useEffect(() => {
    if (sites) {
      setCenter(calculateMapCenter(sites));
    }
  }, [sites]);

  if (sites === undefined) {
    return (
      <Typography variant="h5" textAlign={'center'} marginTop={4}>
        You don't have any sites
      </Typography>
    );
  } else if (sites.length === 0 || center === null) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '40px' }}
      >
        <CircularProgress />
      </Stack>
    );
  } else {
    return (
      <BigCard>
        <Grid container spacing={4}>
          <Grid sm={12} xs={12}>
            <SitesTable sites={sites} setHighlightedSite={setHighlightedSite}></SitesTable>
          </Grid>
        </Grid>
      </BigCard>
    );
  }
};

export default SitesTuya;
