import React, { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Event } from './DataValues';
import { Site } from '../types';
import EventsTable from '../components/EventsTable';
import { getDates } from '../util';

interface EventsDashboardProps {
  sites: Site[];
  serverUrl: string;
}

const EventsDashboard: React.FC<EventsDashboardProps> = ({ sites, serverUrl }) => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (sites === undefined) {
      return;
    }

    const token = localStorage.getItem('user_token');

    const fetchEvents = async () => {
      const { thirdDay, oneYearAgo } = getDates();
      const siteIds = sites.map((site) => site.site_id);

      try {
        const eventsReponse = await axios.get(`${serverUrl}/events_result`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            site_ids: siteIds,
            start: oneYearAgo,
            end: thirdDay
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          }
        });

        setEvents(eventsReponse.data);
      } catch (error) {
        console.error('Error fetching events data:', error);
      }
    };
    if (token !== '') {
      fetchEvents();
    }
  }, [serverUrl, sites]);

  return (
    <Grid container justifyContent="center" style={{ marginTop: 15 }}>
      <Grid item xs={12} md={8}>
        <Typography variant="h4" align="left">
          Past & future events
        </Typography>
        <EventsTable events={events} />
      </Grid>
    </Grid>
  );
};

export default EventsDashboard;
