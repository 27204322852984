import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import { Site } from '../../types';
import StatusChip from './StatusChip';
import { formatDate } from '../../util';
import NewStatusChip from './NewStatusChip';
import DeviceStatusFilterChip from './DeviceStatusFilterChip';
import DSOStatusFilterChip from './DSOStatusFilterChip';

import { DSOTheming, levelTheming } from '../../colors';

interface TableProps {
  sites: Site[];
  setHighlightedSite: React.Dispatch<React.SetStateAction<number | null>>;
}

const SitesTable: React.FC<TableProps> = ({ sites, setHighlightedSite }) => {
  type Order = 'asc' | 'desc';
  const [openDSOMeterPopover, setOpenDSOMeterPopover] = useState(false);
  const [openDeviceStatusPopover, setOpenDeviceStatusPopover] = useState(false);
  const [anchorDSOMeterPopover, setAnchorDSOMeterPopover] = useState<HTMLButtonElement | null>(
    null
  );
  const [anchorDeviceStatusPopover, setAnchorDeviceStatusPopover] =
    useState<HTMLButtonElement | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [displaySites, setDisplaySites] = useState(sites);
  const [filterDSO, setFilterDSO] = useState('All');
  const [filterStatus, setFilterStatus] = useState('All');
  const [filterCountDSO, setFilterCountDSO] = useState({});
  const [filterCountStatus, setFilterCountStatus] = useState({});
  const [statusLabelsWithLevelsPresentInSites, setStatusLabelsWithLevelsPresentInSites] = useState<{
    [key: string]: any;
  }>({});
  const [dsoStatusLabelsWithLevelsPresentInSites, setDsoStatusLabelsWithLevelsPresentInSites] =
    useState({});
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('site_id');
  const navigate = useNavigate();

  const handleShowDSOMeterPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorDSOMeterPopover(event.currentTarget);
    setOpenDSOMeterPopover(true);
  };

  const handleCloseDSOMeterPopover = () => {
    setOpenDSOMeterPopover(false);
  };

  const handleShowDeviceStatusPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorDeviceStatusPopover(event.currentTarget);
    setOpenDeviceStatusPopover(true);
  };

  const handleCloseDeviceStatusPopover = () => {
    setOpenDeviceStatusPopover(false);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const statusLabelsWithLevelsPresentInListOfSites: { [key: string]: string } = {};
    const dsoStatusLabelsWithLevelsPresentInListOfSites: { [key: string]: string } = {};
    sites.forEach((site) => {
      const status = site.params['status'];
      if (status) {
        statusLabelsWithLevelsPresentInListOfSites[status.label] = status.level;
      }
      const meterStatus = site.params['meter_status'];
      if (meterStatus) {
        dsoStatusLabelsWithLevelsPresentInListOfSites[meterStatus] = meterStatus;
      }
    });
    setStatusLabelsWithLevelsPresentInSites(statusLabelsWithLevelsPresentInListOfSites);
    setDsoStatusLabelsWithLevelsPresentInSites(dsoStatusLabelsWithLevelsPresentInListOfSites);
  }, [sites]);

  useEffect(() => {
    let filteredSites = sites.filter((r) => {
      let formattedFilterDSO;
      if (filterDSO === 'Connected') {
        formattedFilterDSO = 'connected';
      } else if (filterDSO === 'In Progress') {
        formattedFilterDSO = undefined;
      } else {
        formattedFilterDSO = filterDSO;
      }
      let statusDSO = r['params']['meter_status'];
      let filterDSOTrue = statusDSO === formattedFilterDSO || filterDSO === 'All';

      let filterDeviceTrue;
      try {
        let statusDevice = r['params']['status']['label'];
        filterDeviceTrue = statusDevice === filterStatus || filterStatus === 'All';
      } catch {
        filterDeviceTrue = false;
      }

      return filterDSOTrue && filterDeviceTrue;
    });
    const updatePageToZeroOnFilterChange = () => {
      setPage(0);
    };
    updatePageToZeroOnFilterChange();
    setDisplaySites(filteredSites);
  }, [filterDSO, filterStatus, sites]);

  const updateFilterCounts = useCallback(() => {
    const getCountWithCurrentFilters = (filterDSO: string, filterStatus: string) => {
      let filteredSites = sites.filter((r) => {
        let formattedFilterDSO;
        if (filterDSO === 'Connected') {
          formattedFilterDSO = 'connected';
        } else if (filterDSO === 'In Progress') {
          formattedFilterDSO = undefined;
        } else {
          formattedFilterDSO = filterDSO;
        }
        let statusDSO = r['params']['meter_status'];
        let filterDSOTrue = statusDSO === formattedFilterDSO || filterDSO === 'All';

        let filterDeviceTrue;
        try {
          let statusDevice = r['params']['status']['label'];
          filterDeviceTrue = statusDevice === filterStatus || filterStatus === 'All';
        } catch {
          filterDeviceTrue = false;
        }

        return filterDSOTrue && filterDeviceTrue ? true : false;
      });
      return filteredSites.length;
    };
    let siteCountDevice = 0;
    siteCountDevice = getCountWithCurrentFilters(filterDSO, 'All');
    setFilterCountStatus((filterCountStatus) => ({
      ...filterCountStatus,
      All: siteCountDevice
    }));
    Object.keys(statusLabelsWithLevelsPresentInSites).forEach((statusLabelPresentInSites) => {
      let siteCountDevice = 0;
      siteCountDevice = getCountWithCurrentFilters(filterDSO, statusLabelPresentInSites);
      setFilterCountStatus((filterCountStatus) => ({
        ...filterCountStatus,
        [statusLabelPresentInSites]: siteCountDevice
      }));
    });
    let siteCountDSO = 0;
    siteCountDSO = getCountWithCurrentFilters('All', filterStatus);
    setFilterCountDSO((filterCountStatus) => ({
      ...filterCountStatus,
      All: siteCountDSO
    }));
    Object.keys(dsoStatusLabelsWithLevelsPresentInSites).forEach((dsoLabelPresentInSites) => {
      let siteCountDSO = 0;
      siteCountDSO = getCountWithCurrentFilters(dsoLabelPresentInSites, filterStatus);
      setFilterCountDSO((filterCountDSO) => ({
        ...filterCountDSO,
        [dsoLabelPresentInSites]: siteCountDSO
      }));
    });
  }, [
    dsoStatusLabelsWithLevelsPresentInSites,
    filterDSO,
    filterStatus,
    sites,
    statusLabelsWithLevelsPresentInSites
  ]);

  useEffect(() => {
    updateFilterCounts();
  }, [filterDSO, updateFilterCounts]);

  const handleSortBy = (property: string) => (_event: React.MouseEvent<unknown>) => {
    if (orderBy !== property) {
      setOrderBy(property);
    } else {
      let isAsc = order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  function descendingComparator(a: Site, b: Site, orderBy: string) {
    if (b[orderBy]! < a[orderBy]!) {
      return -1;
    }
    if (b[orderBy]! > a[orderBy]!) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: Order, orderBy: string): (a: Site, b: Site) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const visibleSites = React.useMemo(
    () =>
      displaySites
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, displaySites, page, rowsPerPage]
  );

  return (
    <>
      <TableContainer style={{ overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" sortDirection={order}>
                <TableSortLabel
                  active={orderBy === 'site_id'}
                  direction={order === 'asc' ? 'desc' : 'asc'}
                  onClick={handleSortBy('site_id')}
                >
                  <b>Site ID</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <b>City</b>
              </TableCell>
              <TableCell align="center">
                <b>Postcode</b>
              </TableCell>
              <TableCell align="center">
                <button
                  onClick={handleShowDSOMeterPopover}
                  style={{
                    backgroundColor: '#F8F9FF',
                    border: 'none',
                    fontSize: '14px',
                    cursor: 'pointer'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <p style={{ marginRight: '6px' }}>
                      <b>DSO Meter</b>
                    </p>
                    <FilterListIcon
                      sx={{
                        backgroundColor: DSOTheming[filterDSO].bgcolor,
                        color: DSOTheming[filterDSO].textColor,
                        borderRadius: '4px'
                      }}
                      style={{ marginTop: '10px' }}
                    />
                  </div>
                </button>
              </TableCell>
              <Popover
                open={openDSOMeterPopover}
                anchorEl={anchorDSOMeterPopover}
                onClose={handleCloseDSOMeterPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '20px',
                    width: '640px'
                  }}
                >
                  <div style={{ marginTop: 'auto', marginBottom: '20px', marginRight: '20px' }}>
                    <b>Filter DSO Meter</b>
                  </div>
                  <Divider style={{ marginBottom: '30px' }} />
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      marginRight: '50px',
                      marginBottom: '10px'
                    }}
                  >
                    <DSOStatusFilterChip
                      label={'All'}
                      level={'neutral'}
                      count={filterCountDSO}
                      active={filterDSO === 'All'}
                      handleClick={setFilterDSO}
                    />
                    {Object.entries(dsoStatusLabelsWithLevelsPresentInSites).map(
                      ([label, level]) => {
                        return (
                          <DSOStatusFilterChip
                            label={label}
                            level={level}
                            count={filterCountDSO}
                            active={filterDSO === label}
                            handleClick={setFilterDSO}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </Popover>
              <TableCell align="center">
                <button
                  onClick={handleShowDeviceStatusPopover}
                  style={{
                    backgroundColor: '#F8F9FF',
                    border: 'none',
                    fontSize: '14px',
                    cursor: 'pointer'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <p style={{ marginRight: '6px' }}>
                      <b>Devices status</b>
                    </p>
                    {filterStatus === 'All' ? (
                      <FilterListIcon
                        sx={{
                          backgroundColor: levelTheming['neutral'].bgcolor,
                          color: levelTheming['neutral'].textColor,
                          borderRadius: '4px'
                        }}
                        style={{ marginTop: '10px' }}
                      />
                    ) : (
                      <FilterListIcon
                        sx={{
                          backgroundColor:
                            levelTheming[statusLabelsWithLevelsPresentInSites[filterStatus]]
                              .bgcolor,
                          color:
                            levelTheming[statusLabelsWithLevelsPresentInSites[filterStatus]]
                              .textColor,
                          borderRadius: '4px'
                        }}
                        style={{ marginTop: '10px' }}
                      />
                    )}
                  </div>
                </button>
              </TableCell>
              <Popover
                open={openDeviceStatusPopover}
                anchorEl={anchorDeviceStatusPopover}
                onClose={handleCloseDeviceStatusPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '20px',
                    width: '600px'
                  }}
                >
                  <div style={{ marginTop: 'auto', marginBottom: '20px', marginRight: '20px' }}>
                    <b>Filter Device Status</b>
                  </div>
                  <Divider style={{ marginBottom: '30px' }} />
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      marginRight: '50px',
                      marginBottom: '10px'
                    }}
                  >
                    <DeviceStatusFilterChip
                      label={'All'}
                      level={'neutral'}
                      count={filterCountStatus}
                      active={filterStatus === 'All'}
                      handleClick={setFilterStatus}
                    />
                    {Object.entries(statusLabelsWithLevelsPresentInSites).map(([label, level]) => {
                      return (
                        <DeviceStatusFilterChip
                          label={label}
                          level={level}
                          count={filterCountStatus}
                          active={filterStatus === label}
                          handleClick={setFilterStatus}
                        />
                      );
                    })}
                  </div>
                </div>
              </Popover>
              <TableCell align="center" sortDirection={order}>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={order === 'asc' ? 'desc' : 'asc'}
                  onClick={handleSortBy('created_at')}
                >
                  <b>Creation date</b>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleSites.map((site) => (
              <TableRow
                key={site.site_id}
                hover={true}
                onMouseEnter={() => setHighlightedSite(site.site_id)}
                onMouseLeave={() => setHighlightedSite(null)}
                onClick={() => navigate(`/site/${site.site_id}/details`)}
              >
                <TableCell align="center">{site.site_id}</TableCell>
                <TableCell align="center">{site.city}</TableCell>
                <TableCell align="center">{site.postcode}</TableCell>
                <TableCell align="center">
                  {site.params['meter_status'] ? (
                    <StatusChip status={site.params['meter_status']} />
                  ) : (
                    <StatusChip status="inProgress" />
                  )}
                </TableCell>
                <TableCell align="center">
                  {site.params['status'] && (
                    <NewStatusChip
                      label={site.params['status']['label']}
                      level={site.params['status']['level']}
                    />
                  )}
                  {/* {site.params['tuya_devices'] && site.params['tuya_devices'].length > 0 ? (
                    <StatusChip status={site.params['tuya_devices'][0]['status']} />
                  ) : (
                    <StatusChip status="notFound" />
                  )} */}
                </TableCell>
                <TableCell align="center">{formatDate(site.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={displaySites.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default SitesTable;
