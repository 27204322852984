import { Typography } from '@mui/material';

const CardTitle = ({ title }) => {
  return (
    <Typography variant="h6" fontWeight={100} fontSize={'29px'}>
      {title}
    </Typography>
  );
};

export default CardTitle;
