import { Typography } from '@mui/material';

const DisplayField = ({ fieldName, fieldValue }) => {
  return (
    <div style={{ marginTop: '13px' }}>
      <Typography variant="caption" sx={{ color: 'rgb(105, 105, 105)' }}>
        {fieldName}
      </Typography>
      <Typography variant="body1" sx={{}}>
        {fieldValue}
      </Typography>
    </div>
  );
};

export default DisplayField;
