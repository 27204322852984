import React from 'react';
import { Stack } from '@mui/material';
import DisplayField from './DisplayField';

import { SmallCard } from '../TiltCard';
import { Site } from '../../types';
import CardTitle from './CardTitle';

function formatDate(isoDate: string): string {
  const date = new Date(isoDate);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1
  const year = date.getUTCFullYear();

  // Format the day and month with leading zeros if needed
  const dayString = day < 10 ? `0${day}` : `${day}`;
  const monthString = month < 10 ? `0${month}` : `${month}`;

  return `${dayString}/${monthString}/${year}`;
}

interface TableProps {
  site: Site;
}

const SiteInfo: React.FC<TableProps> = ({ site }) => {
  const firstNameAnonymized = site.params.first_name[0] + '*********';
  const lastNameAnonymized = site.params.last_name[0] + '*********';

  return (
    <SmallCard>
      <Stack spacing={2} alignItems="flex-start">
        <CardTitle title="Site information" />
        <DisplayField fieldName="First name" fieldValue={firstNameAnonymized} />
        <DisplayField fieldName="Last name" fieldValue={lastNameAnonymized} />
        <DisplayField fieldName="City" fieldValue={site.city} />
        <DisplayField fieldName="Postcode" fieldValue={site.postcode} />
        <DisplayField fieldName="Creation date" fieldValue={formatDate(site.created_at)} />
      </Stack>
    </SmallCard>
  );
};

export default SiteInfo;
