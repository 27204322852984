import { useState, useEffect } from 'react';
import { Chip } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import ReportIcon from '@mui/icons-material/Report';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { levelTheming } from '../../colors';

const chipConfig = {
  neutral: {
    iconColor: levelTheming.neutral.iconColor,
    textColor: levelTheming.neutral.textColor,
    style: {
      active: {
        bgcolor: levelTheming.neutral.bgcolor
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  ineligible: {
    icon: <ReportIcon />,
    iconColor: levelTheming.ineligible.iconColor,
    textColor: levelTheming.ineligible.textColor,
    style: {
      active: {
        bgcolor: levelTheming.ineligible.bgcolor
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  warning: {
    icon: <WarningRoundedIcon />,
    iconColor: levelTheming.warning.iconColor,
    textColor: levelTheming.warning.textColor,
    style: {
      active: {
        bgcolor: levelTheming.warning.bgcolor
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  error: {
    icon: <Error />,
    iconColor: levelTheming.error.iconColor,
    textColor: levelTheming.error.textColor,
    style: {
      active: {
        bgcolor: levelTheming.error.bgcolor
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  },
  normal: {
    icon: <CheckCircle />,
    iconColor: levelTheming.normal.iconColor,
    textColor: levelTheming.normal.textColor,
    style: {
      active: {
        bgcolor: levelTheming.normal.bgcolor
      },
      inactive: {
        bgcolor: 'white'
      }
    }
  }
};

const DeviceStatusFilterChip = ({ active, label, level, count, handleClick }) => {
  const [bgcolor, setBgcolor] = useState(undefined);

  useEffect(() => {
    if (active === true) {
      const { bgcolor } = chipConfig[level].style.active;
      setBgcolor(bgcolor);
    } else {
      const { bgcolor } = chipConfig[level].style.inactive;
      setBgcolor(bgcolor);
    }
  }, [active, label, level]);

  const { icon, iconColor, textColor } = chipConfig[level] || chipConfig.noData;

  return (
    <Chip
      icon={icon}
      label={label + ' (' + count[label] + ')'}
      sx={{
        bgcolor: bgcolor,
        color: textColor,
        marginRight: '10px',
        fontSize: '18px',
        padding: '20px',
        '& .MuiChip-icon': {
          color: iconColor,
          fontSize: '22px',
          marginRight: '-8px'
        }
      }}
      onClick={() => handleClick(label)}
    />
  );
};

export default DeviceStatusFilterChip;
